<template>
  <div>
    <div class="va-row">
      <div class="flex xs12 md12">
        <vuestic-widget>
          <div class="d-flex justify-content-between d">
            <el-input
              style="margin-bottom: 10px; width: 150px;"
              v-model="filters[0].value"
              placeholder="Pesquisar"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
              ></i>
            </el-input>
            <button
              class="btn btn-primary btn-micro"
              @click="showMediumModal(), limparCampos()"
            >
              Cadastrar
            </button>
          </div>

          <div>
            <data-tables
              :data="ferramentas"
              :filters="filters"
              :table-props="tableProps"
              :action-col="actionCol"
              v-loading="loading"
            >
              <el-table-column
                prop="ferramenta"
                label="Ferramenta"
                sortable="custom"
              />
            </data-tables>
          </div>
        </vuestic-widget>
      </div>
    </div>

    <!-- modal para cadastrar Ferramenta -->
    <vuestic-modal
      :show.sync="show"
      ref="mediumModal"
      v-on:ok="cadastrarOuEditarFerramenat()"
    >
      <div
        slot="title"
        v-if="!ferramenta.id"
      >Cadastrar Ferramenta</div>
      <div
        slot="title"
        v-else
      >Editar Ferramenta</div>
      <div>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <p>Nome da Ferramenta:</p>
              <input
                v-model="ferramenta.ferramenta"
                id="simple-input"
                required
              >
              <label
                class="control-label"
                for="simple-input"
              ></label>
            </div>
          </div>
        </fieldset>
      </div>
    </vuestic-modal>
    <vuestic-modal
      :show.sync="show"
      ref="staticModal"
      v-on:ok="deletarFerramenata(ferramenta.id)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja excluir a ferramenta <b>{{ferramenta.ferramenta}}</b>?</div>
    </vuestic-modal>
  </div>
</template>

<script>
import ordenacao from './ordenacao';

export default {
  name: 'lista-ferramentas',
  data() {
    return {
      loading: false,
      show: true,
      ferramentas: [],
      ferramenta: {
        ferramenta: '',
      },
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'EDITAR/EXCLUIR',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              icon: 'el-icon-edit',
              type: 'primary',
              circle: true,
            },
            handler: (row) => {
              this.ferramenta = row;
              this.showMediumModal();
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.ferramenta = row;
              this.showStaticModal();
            },
          },
        ],
      },
      filters: [
        {
          value: '',
          prop: ['ferramenta'],
        },
      ],
    };
  },
  created() {
    this.getFerramentas();
  },
  methods: {
    getFerramentas() {
      this.loading = true;
      this.$axios.get('/api/ferramenta.json').then((res) => {
        this.loading = false;
        this.ferramentas = (res.data).sort(ordenacao.ordenarObjetosPorNomeDaFerramenta);
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
    cadastrarOuEditarFerramenat() {
      if (this.ferramenta.id === undefined) {
        this.cadastrarFerramenata();
      } else {
        this.editarFerramenata(this.ferramenta.id);
      }
    },
    cadastrarFerramenata() {
      this.$axios.post('/api/ferramenta.json', this.ferramenta).then(() => {
        this.message('success', 'Cadastrado com Sucesso!');
        this.getFerramentas();
      }).catch(() => this.message('error', 'Erro ao cadastrar!'));
    },
    message(tipo, msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type: tipo,
        title: msg,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    deletarFerramenata(id) {
      this.$axios.delete(`/api/ferramenta/${id}.json`).then(() => {
        this.message('success', 'Excluido com Sucesso!');
        this.ferramentas.forEach((value, index) => {
          if (value.id === id) {
            this.ferramentas.splice(index, 1);
          }
        });
      }).catch(() => this.message('error', 'Erro ao deletar!!! Verifique se existe(m) cadastro(s) vinculado(s) a este item.'));
    },
    editarFerramenata(id) {
      this.$axios.put(`/api/ferramenta/${id}.json`, this.ferramenta).then(() => {
        this.getFerramentas();
        this.limparCampos();
        this.message('success', 'Editado com Sucesso');
      }).catch(() => this.message('error', 'Erro ao Editar'));
    },
    limparCampos() {
      this.ferramenta = {
        ferramenta: '',
      };
    },
    showMediumModal() {
      this.$refs.mediumModal.open();
    },
  },
};
</script>
<style>
.d {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button {
  height: 50%;
}
</style>
